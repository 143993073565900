import { collection, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { auth, db } from "../utils/firebase";
import CreateCollection from "../components/CreateCollection";
import { signOut } from "firebase/auth";
import { HiOutlineLogout } from "react-icons/hi";
import CollectionButton from "../components/CollectionButton";

export default function Sidebar({ Collection, setCollection, User, setLimit }) {
  const [Groups, setGroups] = useState([]);

  function SignOut() {
    signOut(auth)
      .then(() => {
        sessionStorage.removeItem("abacus");
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (sessionStorage.abacus) {
      let data = JSON.parse(sessionStorage.abacus);
      const qu = query(collection(db, data.uid));
      onSnapshot(qu, (snapshot) =>
        snapshot.docChanges().forEach((item, inx) => {
          if (item.type === "removed") {
            //remove item code
            console.log(item.doc.id);
            setGroups((G) => [...G.filter((el) => el !== item.doc.id)]);
          } else {
            setGroups((G) => [
              ...new Set([...G, item.doc.id].filter((el) => el !== "zInfo")),
            ]);
          }
          if (inx === 0) {
            setCollection(item.doc.id);
          }
        })
      );
    } else {
      sessionStorage.removeItem("abacus");
      window.location.reload();
    }
  }, []);

  return (
    <Container>
      <div className="collections">
        {Groups && Array.isArray(Groups) && Groups.length > 0 ? (
          React.Children.toArray(
            Groups.map((item) => (
              // <div className="collection">
              //   <button
              //     className={`name ${Collection === item ? "active" : ""}`}
              //     onClick={() => setCollection(item)}
              //   >
              //     {item}
              //   </button>
              //   <button >
              //     <FaTrash />
              //   </button>
              // </div>
              <CollectionButton
                active={Collection === item}
                name={item}
                user={User}
                setCollection={setCollection}
              />
            ))
          )
        ) : (
          <p>No collection found.</p>
        )}
        <CreateCollection
          User={User && User}
          setData={setGroups}
          groups={Groups}
          setCollection={setCollection}
        />
      </div>
      <div className="bottom">
        <button onClick={SignOut}>
          <HiOutlineLogout />
          Sign Out
        </button>
      </div>
    </Container>
  );
}
const Container = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  padding-top: 12px;
  height: 100%;
  box-shadow: 0 10px 10px 2px var(--light-shade);
  background-color: var(--light);
  & > .collections {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    padding: 0 0 24px;
    & > .collection {
      display: flex;
      & > button {
        background-color: transparent;
        color: var(--dark);
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
        padding: 0 12px;
        border: none;
        outline: none;
        border-left: 5px solid transparent;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        cursor: pointer;
        &.active {
          border-color: var(--dark);
        }
        &.name {
          flex: 1;
        }
      }
    }
  }
  & > .bottom {
    padding: 24px 0 0;
    & > button {
      background-color: transparent;
      color: var(--dark);
      font-size: 1.2rem;
      font-weight: 500;
      text-align: left;
      padding: 0 12px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }
`;
