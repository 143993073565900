import { deleteDoc, doc } from "firebase/firestore";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { styled } from "styled-components";
import { db } from "../utils/firebase";
import ModalContainer from "./ModalContainer";

export default function CollectionButton({
  active,
  setCollection,
  name,
  user,
}) {
  const [DeleteConfirmModal, setDeleteConfirmModal] = useState(false);

  async function deleteCollection() {
    await deleteDoc(doc(db, user.uid, name));
  }
  return (
    <>
      <Container>
        <button
          className={`name ${active ? "active" : ""}`}
          onClick={() => setCollection(name)}
        >
          {name}
        </button>
        <button onClick={() => setDeleteConfirmModal(true)}>
          <FaTrash />
        </button>
      </Container>
      <ModalContainer show={DeleteConfirmModal} setShow={setDeleteConfirmModal}>
        <ConfirmDeletion>
          <h4>Delete "{name && name}" Collection</h4>
          <div className="buttons">
            <button
              className="cancel"
              onClick={() => setDeleteConfirmModal(false)}
            >
              Cancel
            </button>
            <button className="delete" onClick={deleteCollection}>
              Delete
            </button>
          </div>
        </ConfirmDeletion>
      </ModalContainer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  & > button {
    background-color: transparent;
    color: var(--dark);
    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    padding: 0 12px;
    border: none;
    outline: none;
    border-left: 5px solid transparent;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    cursor: pointer;
    &.active {
      border-color: var(--dark);
    }
    &.name {
      flex: 1;
    }
  }
`;

const ConfirmDeletion = styled.div`
  padding: 24px;
  background-color: var(--light);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    & > button {
      background-color: transparent;
      color: var(--light);
      padding: 8px 24px;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      outline: none;
      border-radius: 24px;
      cursor: pointer;
      &.delete {
        background-color: var(--red);
        &:focus {
          box-shadow: var(--red-shadow);
        }
      }
      &.cancel {
        background-color: var(--blue-dark);
        &:focus {
          box-shadow: var(--blue-dark-shadow);
        }
      }
    }
  }
`;
