import React from "react";
import { styled } from "styled-components";

export default function SolutionCard({ image, title, description }) {
  return (
    <Container>
      <div className="image">
        <img src={image && image} alt={title && title} />
      </div>
      <h4>{title && title}</h4>
      <p>{description && description}</p>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 24px;
  width: min(350px, 100%);
  border-radius: 12px;
  box-shadow: 0 0 10px 2px #d9d9d9;
  & > .image {
    width: 100%;
    margin: auto;
    border-radius: 12px 12px 0 0;
    aspect-ratio: 4/2;
    overflow: hidden;
    position: relative;
    background-color: #000;
    padding: 24px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  & > h4 {
    font-size: 1.4rem;
    padding: 0 24px;
  }
  & > p {
    padding: 0 24px;
    text-align: justify;
  }
`;
