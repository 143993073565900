import React from "react";
import { styled } from "styled-components";

export default function ModalContainer({ show, setShow, children }) {
  return (
    show && (
      <Container>
        <div className="close" onClick={() => setShow(false)}></div>
        <div className="mc_content">{children}</div>
      </Container>
    )
  );
}
const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--dark-t);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  & > .close {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  & > .mc_content {
    max-height: 100%;
    overflow: auto;
    /* position: static;
    z-index: 1; */
  }
`;
