import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import { styled } from "styled-components";
import { auth } from "../utils/firebase";

export default function Login() {
  const provider = new GoogleAuthProvider();

  function SignIn() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // const add = getAdditionalUserInfo(result);
        const user = result.user;
        // ...
        sessionStorage.abacus = JSON.stringify(user);
        window.location.reload();
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Error: ", errorCode, errorMessage, email, credential);
        // ...
      });
  }

  return (
    <Container>
      <button className="signIn" onClick={SignIn}>
        <div className="img-con">
          <img src="/assets/google.svg" alt="google icon" />
        </div>
        Sign in with google
      </button>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > button {
    display: flex;
    gap: 12px;
    font-size: 1.2rem;
    padding: 12px 24px;
    background-color: white;
    box-shadow: 0 0 10px 2px #bbb1b1;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    & > .img-con {
      height: 24px;
      aspect-ratio: 1/1;
      overflow: hidden;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`;
