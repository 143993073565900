import React from "react";
import { styled } from "styled-components";

export default function Header({ Ham, setHam, user }) {
  return (
    <Container>
      <div className="left">
        <div className={`ham`} onClick={() => setHam(!Ham)}>
          <span className={Ham ? "active" : ""}></span>
        </div>
        <div className="logo">
          <img src="/assets/logo.svg" alt="logo" />
        </div>
      </div>
      <div className="right">
        <div className="image">
          <img src={user?.photoURL} alt={user?.displayName} />
        </div>
      </div>
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 12px 24px;
  & > .left {
    display: flex;
    gap: 24px;
    & > .ham {
      margin: auto 0;
      position: relative;
      width: 30px;
      aspect-ratio: 1/1;
      & > span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 100%;
        background-color: var(--dark);
        transform-origin: center;
        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          top: -10px;
          background-color: black;
          transform-origin: center;
        }
        &::after {
          top: 10px;
        }
        &.active {
          background-color: transparent;
          &::after,
          &::before {
            transform: rotate(-45deg);
            top: 0;
          }
          &::after {
            transform: rotate(45deg);
          }
        }
      }
    }
    & > .logo {
      width: 9rem;
      aspect-ratio: 112/59;
      overflow: hidden;
      @media (max-width: 560px) {
        width: 7rem;
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  & > .right {
    display: flex;
    align-items: center;
    gap: 16px;
    & > .image {
      width: 48px;
      aspect-ratio: 1/1;
      overflow: hidden;
      border-radius: 50%;
      @media (max-width: 560px) {
        width: 30px;
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`;
