import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../utils/firebase";
import { styled } from "styled-components";

export default function CreateCollection({ User, groups, setCollection }) {
  const [EditMode, setEditMode] = useState(false);
  const [Name, setName] = useState();

  function createDoc(e) {
    e.preventDefault();

    if (Name) {
      if (!groups.includes(Name)) {
        const docRef = Name && doc(db, User.uid, Name);
        setDoc(docRef, { name: Name, list: [] }, { merge: true });
        setEditMode(false);
        setName(undefined);
      } else {
        setCollection(Name);
        setEditMode(false);
        setName(undefined);
      }
    }
  }

  return (
    <Container>
      {EditMode ? (
        <>
          <form onSubmit={createDoc}>
            <label htmlFor="collectionName">Collection Name</label>
            <input
              type="text"
              id="collectionName"
              name="collectionName"
              value={Name}
              placeholder="New Collection"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <div className="btn">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setEditMode(false);
                }}
              >
                Cancel
              </button>
              <button type="submit">Create</button>
            </div>
          </form>
        </>
      ) : (
        <button onClick={() => setEditMode(true)}>Create new Collection</button>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  & > form {
    border: 1px dashed var(--dark);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 12px;
    & > label {
      font-size: 1rem;
    }
    & > input {
      font-size: 1rem;
      padding: 6px 12px;
      background-color: transparent;
      width: 100%;
      border-radius: 6px;
      outline: none;
      border: none;
      box-shadow: 0 0 10px 0 var(--light-shade);
    }
    & > .btn {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      & > button {
        border: none;
        background-color: var(--dark);
        color: var(--light);
        margin: 6px 0 0;
        padding: 6px 12px;
        border-radius: 12px;
        cursor: pointer;
      }
    }
  }
  & > button {
    border: none;
    background-color: var(--dark);
    color: var(--light);
    margin: 6px 0;
    width: min(400px, 100%);
    padding: 6px 12px;
    border-radius: 12px;
    margin: 6px auto;
    cursor: pointer;
  }
`;
