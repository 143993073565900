import React from "react";
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";

export default function App() {
  if (
    sessionStorage.abacus === undefined ||
    sessionStorage.abacus === null ||
    sessionStorage.abacus === ""
  ) {
    return <Landing />;
  } else {
    return <Dashboard />;
  }
}
