import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { styled } from "styled-components";

export default function AddTask({
  User,
  docName,
  prev = [],
  ping,
  Search,
  int,
}) {
  const [name, setName] = useState(int);
  const [description, setDescription] = useState();
  const [due, setDue] = useState();

  useEffect(() => {
    setName(int);
  }, [int]);

  function createDoc(e) {
    e.preventDefault();
    if (name) {
      let obj;
      if (due) {
        let arr = due.split("-");
        obj = {
          name,
          description: description ? description : "",
          watched: 1,
          added: new Date().toLocaleDateString(),
          completed: "",
          due: `${Number(arr[1])}/${Number(arr[2])}/${Number(arr[0])}`,
        };
      } else {
        obj = {
          name,
          description: description ? description : "",
          watched: 1,
          added: new Date().toLocaleDateString(),
          completed: "",
          due: ``,
        };
      }
      const docRef = doc(db, User.uid, docName);
      setDoc(docRef, { list: [obj, ...prev] }, { merge: true });
      ping((ev) => !ev);
      setDescription(undefined);
      setDue(undefined);
      setName(undefined);
      Search();
    }
  }

  return (
    <Container>
      <form onSubmit={createDoc}>
        <label htmlFor="movieName">Name</label>
        <input
          type="text"
          id="movieName"
          name="movieName"
          value={name}
          placeholder="Name of title"
          onChange={(e) => {
            setName(e.target.value);
            Search(e.target.value);
          }}
          required
        />
        <label htmlFor="contentDescription">Describe task: (Optional)</label>
        <textarea
          name="contentDescription"
          id="contentDescription"
          value={description}
          placeholder="Describe your task, so you don't forget letter"
          onChange={(e) => setDescription(e.target.value)}
        />
        <label htmlFor="dueDate">Due date:(Optional)</label>
        <input
          type="date"
          id="dueDate"
          name="dueDate"
          value={due}
          placeholder="Year of the movie release"
          onChange={(e) => setDue(e.target.value)}
        />
        <button type="submit">Create</button>
      </form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  & > form {
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: max-content;
    gap: 8px;
    width: min(350px, 100%);
    & > label {
      font-size: 1rem;
    }
    & > input,
    & > textarea {
      font-size: 1rem;
      padding: 6px 12px;
      background-color: transparent;
      width: 100%;
      border-radius: 6px;
      outline: none;
      border: none;
      box-shadow: 0 0 10px 0 var(--light-shade);
    }
    & > textarea {
      width: 100%;
      aspect-ratio: 4/3;
      resize: none;
      user-select: text;
    }
    & > button {
      border: none;
      background-color: var(--dark);
      color: var(--light);
      margin: 6px 0 0 auto;
      padding: 12px 24px;
      border-radius: 12px;
      cursor: pointer;
    }
  }
`;
