import React from "react";
import WatchCard from "../components/WatchCard";
import AddMovie from "../components/AddMovie";

export default function WatchList({
  Search,
  SearchData,
  SortedData,
  user,
  Name,
  DATA,
  setLoader,
  setSearch,
}) {
  return (
    <>
      <div className="content">
        {Search ? (
          SearchData && Array.isArray(SearchData) && SearchData.length > 0 ? (
            <div className="card_container">
              {React.Children.toArray(
                SearchData.map((item) => (
                  <WatchCard
                    {...item}
                    user={user}
                    docName={Name}
                    prev={DATA}
                    ping={setLoader}
                  />
                ))
              )}
            </div>
          ) : (
            <>
              Add the data.
              <AddMovie
                int={Search}
                User={user}
                docName={Name}
                prev={DATA}
                ping={setLoader}
                Search={setSearch}
              />
            </>
          )
        ) : SortedData && Array.isArray(SortedData) && SortedData.length > 0 ? (
          <div className="card_container">
            {React.Children.toArray(
              SortedData.map((item) => (
                <WatchCard
                  {...item}
                  user={user}
                  docName={Name}
                  prev={DATA}
                  ping={setLoader}
                />
              ))
            )}
          </div>
        ) : (
          <div className="card_container">
            {React.Children.toArray(
              DATA.map((item) => (
                <WatchCard
                  {...item}
                  user={user}
                  docName={Name}
                  prev={DATA}
                  ping={setLoader}
                />
              ))
            )}
          </div>
        )}
      </div>
    </>
  );
}
