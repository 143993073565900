import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { db } from "../utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import NotFound from "../components/NotFound";
import AddTask from "../components/AddTask";
import CreateCollection from "../components/CreateCollection";
import WatchList from "../views/WatchList";
import AddMovie from "../components/AddMovie";
import TaskCard from "../components/TaskCard";
import { BsChevronRight, BsSearch } from "react-icons/bs";

export default function Body({ Name, user }) {
  const [DATA, setDATA] = useState();
  const [Loader, setLoader] = useState(false);
  const [Search, setSearch] = useState();
  const [SearchData, setSearchData] = useState();
  const [Category, setCategory] = useState(["All", "Added", "Completed"]);
  const [CCategory, setCCategory] = useState("All");
  const [Sorted, setSorted] = useState();

  useEffect(() => {
    const get = async () => {
      const docRef = doc(db, user.uid, Name);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setDATA(data.list);
        if (Name === "Watch List") {
          const dl = data.list;
          let str = "";
          for (let el of dl) {
            if (!str.toLocaleLowerCase().includes(el.type.toLowerCase())) {
              str += `${el.type}-`;
            }
          }
          str = str.substring(0, str.length - 2);
          setCategory((C) => [
            ...new Set([
              ...C,
              ...str.split("-").filter((el) => el !== "" && isNaN(Number(el))),
            ]),
          ]);
        } else {
          setCategory((C) => [...new Set([...C])]);
        }
      } else {
        console.error("No such document!");
      }
    };
    (Loader || (user && user.uid && Name)) && get();
  }, [Name, user, Loader]);

  function handleSearch(e) {
    let val = e.target.value;
    setSearch(val);
    if (val === null || val === undefined || val === "") {
      setSearchData(undefined);
    } else {
      let arr = [...DATA];
      let a2 = arr.filter((el) =>
        el.name.toLowerCase().includes(val.toLocaleLowerCase())
      );
      setSearchData(a2);
    }
  }

  //   console.log(DATA, user);

  function categorySort(param) {
    switch (param) {
      case "All":
        setSorted(undefined);
        break;
      case "Completed":
        setSorted([...DATA].filter((el) => el.watched === 3));
        break;
      case "Added":
        setSorted([...DATA].filter((el) => el.watched === 1));
        break;
      case "On Process":
        setSorted([...DATA].filter((el) => el.watched === 2));
        break;
      case "Movie":
        setSorted(
          [...DATA].filter((el) => el.type.toLocaleLowerCase() === "movie")
        );
        break;
      case "TV":
        setSorted(
          [...DATA].filter((el) => el.type.toLocaleLowerCase().includes("tv"))
        );
        break;

      default:
        break;
    }
  }

  return (
    <Container>
      {Name ? (
        DATA && Array.isArray(DATA) && DATA.length > 0 ? (
          <div className="container">
            <p>
              {user.displayName}
              <BsChevronRight />
              {Name}
            </p>
            <div className="search">
              <span>
                <BsSearch />
              </span>
              <input type="text" value={Search} onChange={handleSearch} />
            </div>
            {Name === "Watch List" ? (
              <>
                <div className="category_sort">
                  {React.Children.toArray(
                    Category.map((item) => (
                      <button
                        className={item === CCategory ? "active" : ""}
                        onClick={() => {
                          setCCategory(item);
                          categorySort(item);
                        }}
                      >
                        {item}
                      </button>
                    ))
                  )}
                </div>
                <WatchList
                  Search={Search}
                  setSearch={setSearch}
                  SearchData={SearchData}
                  SortedData={Sorted}
                  user={user}
                  Name={Name}
                  DATA={DATA}
                  setLoader={setLoader}
                />
              </>
            ) : (
              <div className="content">
                {Search ? (
                  SearchData &&
                  Array.isArray(SearchData) &&
                  SearchData.length > 0 ? (
                    <div className="card_container">
                      {React.Children.toArray(
                        SearchData.map((item) => (
                          <TaskCard
                            {...item}
                            user={user}
                            docName={Name}
                            prev={DATA}
                            ping={setLoader}
                          />
                        ))
                      )}
                    </div>
                  ) : (
                    <>
                      <p style={{ textAlign: "center" }}>
                        The item you are looking for is not available. You can
                        add the data below.
                      </p>
                      <AddTask
                        Search={setSearch}
                        User={user}
                        docName={Name}
                        int={Search}
                        ping={setLoader}
                        prev={DATA}
                      />
                    </>
                  )
                ) : (
                  <div className="card_container">
                    {React.Children.toArray(
                      DATA.map((item) => (
                        <TaskCard
                          {...item}
                          user={user}
                          docName={Name}
                          prev={DATA}
                          ping={setLoader}
                        />
                      ))
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="notFound">
            <NotFound />
            <p>
              No {Name === "Watch List" ? "Content" : "Task"} available to view.
            </p>
            <p>
              Add some {Name === "Watch List" ? "Content" : "Task"} get more
              feature.
            </p>
            {Name === "Watch List" ? (
              <AddMovie
                Search={setSearch}
                User={user}
                docName={Name}
                int={Search}
                ping={setLoader}
                prev={DATA}
              />
            ) : (
              <AddTask
                Search={setSearch}
                User={user}
                docName={Name}
                int={Search}
                ping={setLoader}
                prev={DATA}
              />
            )}
          </div>
        )
      ) : (
        <div className="notFound">
          <NotFound />
          <p>Add collection to see options.</p>
          <CreateCollection />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  & > .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    & > p {
      font-weight: 500;
      color: var(--dark-s);
      display: flex;
      align-items: center;
      gap: 2px;
      flex-wrap: wrap;
    }
    & > .search {
      display: flex;
      align-items: center;
      box-shadow: 0 0 10px 4px var(--light-shade);
      border-radius: 16px;
      width: min(600px, 100%);
      margin: auto;
      & > span {
        color: var(--dark);
        padding-left: 12px;
      }
      & > input {
        flex: 1;
        border-radius: 16px;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 12px;
      }
    }
    & > .category_sort {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
      & > button {
        outline: none;
        border: 1px solid var(--dark);
        background-color: transparent;
        color: var(--dark);
        padding: 4px 10px;
        border-radius: 12px;
        cursor: pointer;
        &.active {
          color: var(--light);
          background-color: var(--dark);
        }
      }
    }
    & > .content {
      height: 100%;
      overflow-y: auto;
      & > .card_container {
        /* display: flex;
        flex-wrap: wrap; */
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
        gap: 12px;
      }
    }
  }
  & > .notFound {
    text-align: center;
    height: 100%;
    overflow-y: auto;
  }
`;
