import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { styled } from "styled-components";
import { db } from "../utils/firebase";
import { FaTrash } from "react-icons/fa";
import ModalContainer from "./ModalContainer";

export default function TaskCard({
  name,
  description,
  watched,
  added,
  completed,
  due,
  prev,
  user,
  docName,
  ping,
}) {
  const [DeleteConfirmModal, setDeleteConfirmModal] = useState(false);
  function markDone() {
    const docRef = name && doc(db, user.uid, docName);
    let arr = [...prev].filter((el) => el.name !== name);
    setDoc(
      docRef,
      {
        list: [
          ...arr,
          {
            name,
            description,
            due,
            watched: 3,
            added,
            completed: new Date().toLocaleDateString(),
          },
        ],
      },
      { merge: true }
    );
    ping((ev) => !ev);
  }

  function markAdded() {
    const docRef = name && doc(db, user.uid, docName);
    let arr = [...prev].filter((el) => el.name !== name);
    setDoc(
      docRef,
      {
        list: [
          { name, description, due, watched: 1, added, completed: "" },
          ...arr,
        ],
      },
      { merge: true }
    );
    ping((ev) => !ev);
  }

  function markProcess() {
    const docRef = name && doc(db, user.uid, docName);
    let arr = [...prev].filter((el) => el.name !== name);
    setDoc(
      docRef,
      {
        list: [
          ...arr,
          { name, description, due, watched: 2, added, completed: "" },
        ],
      },
      { merge: true }
    );
    ping((ev) => !ev);
  }

  function deleteTask() {
    const docRef = name && doc(db, user.uid, docName);
    let arr = [...prev].filter((el) => el.name !== name);
    setDoc(
      docRef,
      {
        list: [...arr],
      },
      { merge: true }
    );
    ping((ev) => !ev);
  }

  return (
    <Container
      className={
        watched && watched === 3 ? "completed" : watched === 2 ? "process" : ""
      }
    >
      <div className="title">
        Name: <h4>{name && name}</h4>
      </div>
      <p>
        Description:{" "}
        {description && description === "TV" ? "TV series." : description}
      </p>
      <div className="dates">
        <p>
          Status:{" "}
          {watched && watched === 3
            ? "Completed"
            : watched === 2
            ? "On Process"
            : "Added"}
          . {due ? "Due" : ""}{" "}
          {watched && (watched === 1 || watched === 2)
            ? due
              ? due
              : added
            : completed
            ? completed
            : added}
        </p>
      </div>
      <div className="buttons">
        {watched && watched === 3 ? (
          <button onClick={markAdded}>Reset</button>
        ) : watched === 2 ? (
          <button onClick={markDone}>Mark Done</button>
        ) : (
          <button onClick={markProcess}>Move to processing</button>
        )}
        <button onClick={() => setDeleteConfirmModal(true)}>
          <FaTrash />
        </button>
        <ModalContainer
          show={DeleteConfirmModal}
          setShow={setDeleteConfirmModal}
        >
          <ConfirmDeletion>
            <h1>You are about to delete: "{name && name}"</h1>
            <div className="buttons">
              <button
                className="cancel"
                onClick={() => setDeleteConfirmModal(false)}
              >
                Cancel
              </button>
              <button className="delete" onClick={deleteTask}>
                Delete
              </button>
            </div>
          </ConfirmDeletion>
        </ModalContainer>
      </div>
    </Container>
  );
}

const Container = styled.section`
  border-radius: 8px;
  background-color: var(--pink);
  padding: 12px 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--dark-s);
  font-weight: 500;
  &.completed {
    background-color: var(--green);
  }
  &.process {
    background-color: var(--blue);
  }
  & > .title {
    display: flex;
    gap: 8px;
    text-transform: capitalize;
    & > h4 {
      word-break: break-all;
    }
  }
  & > p {
    word-break: break-all;
  }
  & > .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0 0;
    & > button {
      background-color: light;
      border: none;
      outline: none;
      border-radius: 8px;
      padding: 6px 12px;
      cursor: pointer;
    }
  }
`;

const ConfirmDeletion = styled.div`
  padding: 24px;
  background-color: var(--light);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    & > button {
      background-color: transparent;
      color: var(--light);
      padding: 8px 24px;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      outline: none;
      border-radius: 24px;
      cursor: pointer;
      &.delete {
        background-color: var(--red);
        &:focus {
          box-shadow: var(--red-shadow);
        }
      }
      &.cancel {
        background-color: var(--blue-dark);
        &:focus {
          box-shadow: var(--blue-dark-shadow);
        }
      }
    }
  }
`;
