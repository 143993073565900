import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { styled } from "styled-components";

export default function AddMovie({
  User,
  docName,
  prev = [],
  ping,
  Search,
  int,
}) {
  const [name, setName] = useState(int);
  const [type, setType] = useState();
  const [year, setYear] = useState();

  useEffect(() => {
    setName(int);
  }, [int]);

  function createDoc(e) {
    e.preventDefault();
    if (name && type && year) {
      let obj = {
        name,
        type,
        year,
        watched: 1,
        added: new Date().toLocaleDateString(),
        completed: "",
        due: "",
      };
      const docRef = doc(db, User.uid, docName);
      setDoc(docRef, { list: [obj, ...prev] }, { merge: true });
      ping((ev) => !ev);
      setType(undefined);
      setYear(undefined);
      setName(undefined);
      Search();
    }
  }

  return (
    <Container>
      <form onSubmit={createDoc}>
        <label htmlFor="movieName">Name</label>
        <input
          type="text"
          id="movieName"
          name="movieName"
          value={name}
          placeholder="Name of title"
          onChange={(e) => {
            setName(e.target.value);
            Search(e.target.value);
          }}
          required
        />
        <label htmlFor="contentType">Type:(Movie/TV/Documentary etc..)</label>
        <input
          type="text"
          id="contentType"
          name="contentType"
          value={type}
          placeholder="Movie or TV or Documentary..."
          onChange={(e) => setType(e.target.value)}
          required
        />
        <label htmlFor="releasingYear">Releasing Year:</label>
        <input
          type="number"
          id="releasingYear"
          name="releasingYear"
          value={year}
          placeholder="Year of the movie release"
          onChange={(e) => setYear(e.target.value)}
          required
        />
        <button type="submit">Create</button>
      </form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  text-align: left;
  & > form {
    padding: 24px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > label {
      font-size: 1rem;
    }
    & > input {
      font-size: 1rem;
      padding: 6px 12px;
      background-color: transparent;
      width: 100%;
      border-radius: 6px;
      outline: none;
      border: none;
      box-shadow: 0 0 10px 0 var(--light-shade);
    }
    & > button {
      border: none;
      background-color: var(--dark);
      color: var(--light);
      margin: 6px 0 0 auto;
      padding: 12px 24px;
      border-radius: 12px;
      cursor: pointer;
    }
  }
`;
