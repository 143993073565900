import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import Body from "../layout/Body";

export default function Dashboard() {
  const [Ham, setHam] = useState(false);
  const [Collection, setCollection] = useState(false);
  const [User, setUser] = useState(false);
  const [Limit, setLimit] = useState(0);

  useEffect(() => {
    if (sessionStorage.abacus) {
      let data = JSON.parse(sessionStorage.abacus);
      setUser(data);
    } else {
      sessionStorage.removeItem("abacus");
      window.location.reload();
    }
  }, []);

  console.log(Limit);

  return (
    <Container>
      <Header Ham={Ham} setHam={setHam} user={User} />
      <div className="body">
        {Ham && <div className="close" onClick={() => setHam(false)} />}
        <div className={`sidebar ${Ham ? "active" : ""}`}>
          <Sidebar
            Collection={Collection}
            setCollection={setCollection}
            setUser={setUser}
            User={User}
            setLimit={setLimit}
          />
        </div>
        <main>
          <Body Name={Collection} user={User} />
        </main>
      </div>
    </Container>
  );
}
const Container = styled.div`
  height: 100dvh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & > .body {
    height: calc(100% - 6.3rem);
    position: relative;
    display: flex;
    @media (max-width: 560px) {
      height: calc(100% - 5.5rem);
    }
    & > .close {
      display: none;
      @media (max-width: 560px) {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--dark-t);
      }
    }
    & > .sidebar {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      width: min(70%, 300px);
      z-index: 1;
      &.active {
        transform: translateX(0);
        @media (min-width: 560px) {
          position: static;
        }
      }
    }
    & > main {
      padding: 24px;
      padding-top: 12px;
      flex: 1;
      transition: width 300ms ease-in-out;
      height: 100%;
    }
  }
`;
