import React from "react";
import { styled } from "styled-components";

export default function NotFound() {
  return (
    <Container>
      <img src="/assets/404.svg" alt="not found illustration" />
    </Container>
  );
}
const Container = styled.div`
  width: min(400px, 90%);
  aspect-ratio: 16/10;
  margin: auto;
  overflow: hidden;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
