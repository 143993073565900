import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { styled } from "styled-components";
import { db } from "../utils/firebase";

export default function WatchCard({
  name,
  type,
  year,
  watched,
  added,
  completed,
  prev,
  user,
  docName,
  ping,
}) {
  function markDone() {
    const docRef = name && doc(db, user.uid, docName);
    let arr = [...prev].filter((el) => el.name !== name);
    setDoc(
      docRef,
      {
        list: [
          ...arr,
          {
            name,
            type,
            year,
            watched: 3,
            added,
            completed: new Date().toLocaleDateString(),
          },
        ],
      },
      { merge: true }
    );
    ping((ev) => !ev);
  }

  function markAdded() {
    const docRef = name && doc(db, user.uid, docName);
    let arr = [...prev].filter((el) => el.name !== name);
    setDoc(
      docRef,
      {
        list: [{ name, type, year, watched: 1, added, completed: "" }, ...arr],
      },
      { merge: true }
    );
    ping((ev) => !ev);
  }

  return (
    <Container className={watched && watched === 3 ? "completed" : ""}>
      <div className="title">
        Name: <h4>{name && name}</h4>
      </div>
      <p>
        Type: {type && type === "TV" ? "TV series." : type} ({year && year})
      </p>
      <p>
        Status: {watched && watched === 3 ? "Watched" : "Added"} on (
        {completed && completed !== "" ? completed : added && added})
      </p>
      {watched && watched === 3 ? (
        <button onClick={markAdded}>Mark Added</button>
      ) : (
        <button onClick={markDone}>Mark Completed</button>
      )}
    </Container>
  );
}

const Container = styled.section`
  border-radius: 8px;
  background-color: var(--pink);
  padding: 12px 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--dark-s);
  font-weight: 500;
  &.completed {
    background-color: var(--green);
  }
  & > .title {
    display: flex;
    gap: 8px;
    text-transform: capitalize;
    & > h4 {
      word-break: break-all;
    }
  }
  & > p {
    word-break: break-all;
  }
  & > button {
    background-color: light;
    border: none;
    outline: none;
    margin: 6px 0 0 auto;
    border-radius: 8px;
    padding: 6px 12px;
    cursor: pointer;
  }
`;
