import React from "react";
import { styled } from "styled-components";
import Login from "../components/Login";
import SolutionCard from "../components/SolutionCard";

export default function Landing() {
  const SOLs = [
    {
      image: "/assets/add.svg",
      title: "Add, Search, Manage easily.",
      description:
        "Add, Search, and manage your task easily with enhanced search, auto suggestion to add task.",
    },
    {
      image: "/assets/group.svg",
      title: "Group your task.",
      description:
        "Create your different task group for your work, study, and home. So you never get lost in lots of tasks.",
    },
    {
      image: "/assets/stat.svg",
      title: "Stay on priority.",
      description:
        "See due tasks first, and personalized statistics on your task commitment to understand where to focus better.",
    },
  ];
  return (
    <Container>
      <section className="hero">
        <div className="left">
          <h1>
            An One place
            <br />
            task manager.
          </h1>
          <p>
            In a world of super changing world remember and complete your task
            in one place.
          </p>
          <Login />
        </div>
        <div className="right">
          <div className="img-con">
            <img src="/assets/hero.svg" alt="todo illustration" />
          </div>
        </div>
      </section>
      <section className="solutions">
        <h2>Our Solutions</h2>
        <div className="content">
          {React.Children.toArray(
            SOLs.map((item) => <SolutionCard {...item} />)
          )}
        </div>
      </section>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > section {
    min-height: 100dvh;
    padding: 24px;
    &.hero {
      background-color: #000;
      color: white;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 24px;
      @media (max-width: 560px) {
        display: flex;
        flex-direction: column;
      }
      & > .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        text-align: center;
        & > h1 {
          font-size: 4.5rem;
          text-transform: capitalize;
        }
        & > p {
          font-size: 1.4rem;
          max-width: 500px;
          margin-bottom: 12px;
        }
      }
      & > .right {
        & > .img-con {
          width: 90%;
          margin: auto;
          aspect-ratio: 1/1;
          overflow: hidden;
          position: relative;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
    &.solutions {
      display: flex;
      flex-direction: column;
      & > h2 {
        font-size: 3rem;
        text-align: center;
      }
      & > .content {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        align-items: center;
        justify-content: center;
        padding: 24px 0;
        margin: auto 0;
      }
    }
  }
`;
